<template>
  <div class="password">
    <Nav></Nav>
    <div class="pass-con">
      <div class="pass-title">您的位置：<span style="color: red">首页</span>>>找回密码</div>
      <div class="pass-detail">
        <div class="tip">请按步骤进行以下操作：</div>
        <div class="reset">
          <div class="first" v-if="step == 1">
            <div class="seq">请输入账户所绑定的手机号:</div>
            <div>
              <span>手机：</span>
              <el-input v-model="mobile" clearable></el-input>
            </div>
            <div class="yanzhengCode">
              <span>图形验证码：</span>
              <el-input v-model="imgCode" clearable></el-input>
              <img :src="codeImg" alt="" class="yan-img" v-if="!$rtm.store.logined" @click="onRetrieveCode" />
            </div>
            <div>
              <span>短信验证码：</span>
              <el-input v-model="mobileCode" clearable></el-input>
              <el-button @click="onFirst">获取手机验证码</el-button>
            </div>
            <div class="step-one">
              <el-button type="success" @click="onTwo">下一步</el-button>
            </div>
          </div>

          <div class="three" v-if="step == 2">
            <!-- <div class="three"> -->
            <div class="seq">请输入新设的密码:</div>
            <div>
              <span>新密码：</span>
              <el-input v-model="newPassWord" clearable @blur="onRegClick"></el-input>
            </div>
            <div>
              <span>重复新密码：</span>
              <el-input v-model="repeat" clearable></el-input>
            </div>
            <el-button type="success" @click="onReset">确认新密码</el-button>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RDUtils from '@/api/RDUtils'
import RD from '@/api/RD'
import SHA1 from 'js-sha1'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      myInfo: {}, // 个人信息
      mobile: '', //手机号
      oldPassWord: '', // 原密码
      newPassWord: '', // 重复密码
      repeat: '', // 重复密码
      mobileCode: '', // 手机验证码
      imgCode: '', //图形验证码
      codeImg: '', // 验证码的图片路径
      step: 1, // 第一步
    }
  },
  created() {
    this.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
  },
  methods: {
    refreshCode() {
      var thiz = this
      thiz.codeImg = RD.user().icodeImage_URL() + '?' + new Date().getTime()
    },
    onRetrieveCode() {
      this.refreshCode()
    },
    // 第一步验证
    onFirst() {
      var thiz = this
      if (thiz.mobile == '') {
        thiz.$message('手机号不能为空')
        return
      }
      if (thiz.imgCode == '') {
        thiz.$message('图形验证码不能为空')
        return
      }

      RD.user()
        .requestSMSForResetPassword(thiz.mobile, thiz.imgCode)
        .then(() => {
          thiz.$message('验证码已发送，请在10分钟的有效时间内完成验证')
        })
    },
    onTwo() {
      var thiz = this
      if (thiz.mobileCode == '') {
        thiz.$message('短信验证码不能为空')
        return
      }
      RD.pure()
        .user()
        .findBackPassword(thiz.mobileCode)
        .then(() => {
          thiz.step = 2
        })
        .catch((error) => {
          this.$alert(error.msg, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
          })
            .then(() => {})
            .catch(() => {})
        })
    },
    //   重置密码
    onReset() {
      var thiz = this
      if (thiz.newPassWord == '') {
        thiz.$message('新密码不能为空')
        return
      }
      if (thiz.newPassWord != thiz.repeat) {
        thiz.$message('新密码两次输入不一致')
        return
      }
      if (thiz.newPassWord != '' && !RDUtils.checkWeekPassword(thiz.newPassWord)) {
        alert('新密码应至少8位，包含数字，大写和小写和特殊符号全部')
        return
      }
      RD.user()
        .resetPasswordAfterConfirm(SHA1(thiz.newPassWord))
        .then(() => {
          thiz.$message('修改密码成功')
          thiz.$router.push('/index.html')
        })
    },
    // 验证强密码
    onRegClick() {
      var thiz = this
      console.log('000')
      if (thiz.newPassWord != '' && !RDUtils.checkWeekPassword(thiz.newPassWord)) {
        alert('新密码应至少8位，包含数字，大写和小写和特殊符号全部')
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped lang="less">
.password {
  height: auto;
  background-color: #f1f1f1;
  .pass-con {
    width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 38rem);
    .pass-title {
      height: 40px;
      background-color: #ccc;
      font-size: 1.6rem;
      line-height: 45px;
      text-align: left;
      padding-left: 10px;
    }
    .pass-detail {
      min-height: 61.8rem;
      background-color: #dfdfdf;
      text-align: left;
      .tip {
        padding-top: 10px;
        font-size: 1.5rem;
        font-weight: bold;
        margin-left: 10px;
      }
      .reset {
        margin-left: 30px;
        margin-top: 30px;
        .first {
          height: 250px;
          position: relative;

          .seq {
            height: 30px;
            line-height: 30px;
            color: red;
            font-size: 1.8rem;
          }
          .el-button {
            margin-left: 5px;
          }
          div {
            span {
              font-size: 1.5rem;
            }
          }
        }
        .second,
        .three {
          height: 250px;
          position: relative;
          .seq {
            height: 30px;
            line-height: 30px;
            color: red;
            font-size: 1.6rem;
          }
          .el-button {
            position: absolute;
            left: 300px;
            bottom: 20px;
          }
        }
        .yanzhengCode {
          height: 50px;
          display: flex;
          span {
            display: inline-block;
            width: 95px;
            height: 50px;
            line-height: 60px;
          }
          .el-input {
            margin-left: 3px;
          }
          img {
            margin-left: 5px;
            margin-top: 10px;
          }
        }
        .step-one {
          .el-button {
            margin-left: 400px;
            margin-top: 20px;
          }
        }
        div {
          height: 50px;
          font-size: 1.2rem;
          margin: 10px 0px;
          span {
            display: inline-block;
            width: 95px;
          }
          .el-input {
            width: 300px;
          }
        }
      }
    }
  }
}
</style>
